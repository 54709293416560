#Contact {
    margin: 0;
    background-color: #333333;
    padding-top: 50px;
    min-height: 100vh;
    font-family: "Times New Roman", "TimesNewRoman ALT",  sans-serif;
}

#Contact .title, #Contact .contact-data {
    color: #AAAAAA;
    text-align: center;
    
}

#Contact .title {
    font-size: 2rem;
}
#Contact .contact-data {
    font-size: 1.5rem;
}

#Contact .contact-data a {
    color: cyan;
    text-decoration: none;
}

#Contact .email-sending-container {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

#Contact .email-sending-box {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    min-width: 400px;
    align-content: center;
    justify-content: center;
    text-align: center;
}

#Contact .input {
    margin-bottom: 20px;
}

#Contact .button {

    transition: 0.2s;
    transition-timing-function: ease-in;
    padding: 20px 20% 20px 20%;
    background-color: #333333;
    color: #AAAAAA;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    margin-bottom: 50px;
    border-radius: 8px;
    border: solid;
    border-color: #AAAAAA;
    border-width: 2px;
}

#Contact .input {
    border-radius: 8px;
    border: solid;
    border-color: #AAAAAA;
    border-width: 2px;
}

#Contact .button:hover {

    background-color: white;
    color: black;
    transition: 0.3s;
    transition-timing-function: ease-out;

}

#Contact .label {
    margin-bottom: 8px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    overflow: hidden;
}

#Contact .message {
    max-width: 98.5%;
    min-width: 98.5%;
    min-height: 5rem;
}

@media (max-width: 550px) {

    #Contact .email-sending-container {
        width: 80vw;
        margin-left: 10vw;
        margin-right: 10vw;
    }
    #Contact .email-sending-box {
        width: 80%;
        max-width: 80vw;
    }
    #Contact .message, #Contact .input, #Contact .label {
        max-width: calc(80vw - 20px);
        min-width: calc(80vw - 20px);
        width: calc(80vw - 20px);
        
    }


}

@media (max-width: 420px) {
    #Contact .button {
        max-width: 55%;
    }
}
