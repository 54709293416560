#Landing {
    margin: 0;
    background-color: #0066FF;
    padding-top: 50px;
    min-height: 100vh;
    font-family: "Times New Roman", "TimesNewRoman ALT",  sans-serif;
}

#Landing .title {
    margin-top: 40px;
    text-align: center;
    font-size: 2rem;
}

@media (min-width: 551px) {

    #Landing .mobile, #Landing .mobile-container {
        visibility: hidden;
        height: 0;
        margin: 0 !important; 
    }

}

#Landing .button {

    transition: 0.2s;
    transition-timing-function: ease-in;
    padding: 20px 20% 20px 20%;
    background-color: #333333;
    color: #AAAAAA;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;

}

#Landing .button:hover {

    background-color: white;
    color: black;
    transition: 0.3s;
    transition-timing-function: ease-out;

}

#Landing .nobold {
    font-weight: normal;
}

#Landing .text-content {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

#Landing .title, #Landing .text  {
    color: white;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

#Landing .button-container {
    margin-top: 40px;
}

#Landing .image {
    margin-left: 0;
    margin-right: auto;
    max-width: 400px;
    width: 40%;
}

#Landing .text {
    max-width: 600px;
    text-align: justify;
    font-size: 1.4rem;
}

#Landing .content {
    margin-top: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 0;
}

@media (max-width: 1050px) {
    #Landing .content {
        flex-direction: column;
    }

    #Landing .title {
        width: 90%;
    }

    #Landing .text {
        font-size: 1.35rem;
    }

    #Landing .text {
        width: 90%;
    }
    #Landing .image {
        margin-left: auto;
        width: 40%;
        min-width: 350px;
        margin-top: 50px;
    }
}

@media (max-width: 550px) {

    #Landing .first-paragraph {
        margin-bottom: 20px;
    }

    #Landing .content {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;

    }

    #Landing .button {
        font-size: 1.45rem;
        padding: 20px 18% 20px 18%;
        border-radius: 20px;
        margin-top: 20px;
    }

    #Landing .button-container {
        margin-bottom: 40px;
    }

    #Landing .border {
        border-width: 6px;
        border-color: #555555;
        border-style: solid;
        border-right: none;
        border-bottom: none;
    }

    #Landing .desktop {
        visibility: hidden;
        height: 0;
        margin: 0 !important;
        padding: 0 !important;
    }


    #Landing .mobile-container {
        margin-bottom: 40px !important;
        margin-top: 20px !important;
    }

    #Landing .mobile-container .button {
        border-radius: 40px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    #Landing .text {
        font-size: 1.1rem;
    }

    #Landing .title {
        text-align: center;
        font-size: 1.25rem;
        width: 94%;
    }

    #Landing .image {
        margin-left: auto;
        width: 40%;
        min-width: 250px;
        margin-top: 50px;
    }
}
