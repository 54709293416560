#Synopse {
    margin: 0;
    background-color: #333333;
    padding-top: 50px;
    min-height: 100vh;
    font-family: "Times New Roman", "TimesNewRoman ALT",  sans-serif;

}

#Synopse .title {
    text-align: center;
    font-size: 2rem;
}

#Synopse .title, #Synopse .text {
    color: #AAAAAA;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

#Synopse .image {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    width: 40%;
    margin-bottom: 50px;
}

#Synopse .text {
    margin-right: 0;
    max-width: 600px;
    text-align: justify;
    font-size: 1.4rem;
 
}

#Synopse .content {
    margin-top: 50px;
    display: flex;
    align-items: flex-start;
    gap: 0%;
}

@media (max-width: 1050px) {
#Synopse .content {
    flex-direction: column;
}
#Synopse .text {
    margin-right: auto;
    margin-bottom: 30px;
    width: 90%;
}

#Synopse .image {
    margin-left: auto;
    width: 40%;
    min-width: 350px;
    margin-top: 50px;
}

#Synopse .title {
    width: 90%;
}

}

@media (max-width: 550px) {
#Synopse .content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

}

#Synopse .text {
    font-size: 1.1rem;
}

#Synopse .title {
    text-align: center;
    font-size: 1.5rem;
    width: 80%;
}

#Synopse .image {
    margin-left: auto;
    width: 40%;
    min-width: 250px;
    margin-top: 40px;
}
}
