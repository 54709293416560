#AboutMe {
    margin: 0;
    background-color: #333333;
    padding-top: 50px;
    min-height: 100vh;
    padding-bottom: 50px;
    font-family: "Times New Roman", "TimesNewRoman ALT",  sans-serif;

}

#AboutMe .title {
    text-align: center;
    font-size: 2rem;
}

#AboutMe .title, #AboutMe .text {
    color: #AAAAAA;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

#AboutMe .image {
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 5px;
    max-width: 500px;
    width: 100%;
}

#AboutMe .instagram {
    margin-left: 0;
    margin-right: auto;
    max-width: 150px;
    min-width: 40px;
    width: 12%;

}

#AboutMe .image-container {
    align-items: flex-start;
    display: flex;
    width: 40%;
    flex-direction: column;
}

#AboutMe .text {
    margin-right: 0; 
    max-width: 600px;
    text-align: justify;
    font-size: 1.4rem;

}

#AboutMe .content {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
}

@media (max-width: 1050px) {
#AboutMe .content {
    flex-direction: column;
}
#AboutMe .text {
    margin-right: auto;
    margin-bottom: 30px;
}

#AboutMe .image {
    margin-bottom: 5px;
}

#AboutMe .image-container {
    gap: 0%;
}

}

@media (max-width: 650px) {
#AboutMe .content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;

}

#AboutMe .image {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-width: 250px;
    margin-top: 50px;
}

#AboutMe .image-container {
    width: 80%;
    
}

#AboutMe .instagram {
    width: 10%;
}

}

@media (max-width: 550px) {
#AboutMe .text {
    font-size: 1.1rem;
}

#AboutMe .title {
    text-align: center;
    font-size: 1.5rem;
    width: 80%;
}

}
