#Buy {
    margin: 0;
    background-color: #0066FF;
    padding-top: 50px;
    min-height: 100vh;
    font-family: "Times New Roman", "TimesNewRoman ALT",  sans-serif;
    color: white;
    padding-bottom: 50px;

}

#Buy .title {
    font-size: 2rem;
}

#Buy .title, #Buy .subtitle, #Buy .price {
    text-align: center;
}

#Buy .subtitle {
    font-size: 1.5rem;
}

#Buy .price {
    font-size: 1.3rem;
}

#Buy .button {

    text-align: center;
    transition: 0.2s;
    transition-timing-function: ease-in;
    padding: 20px 10% 20px 10%;
    background-color: #333333;
    color: #AAAAAA;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    min-width: 10rem;

}

#Buy .button:hover {

    background-color: white;
    color: black;
    transition: 0.3s;
    transition-timing-function: ease-out;

}

#Buy .image {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    width: 60%;
}

#Buy .link-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10%;
    
}

#Buy .link-data {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border-color: white;
    border-style: solid;
    padding-bottom: 40px;
}

@media (max-width: 1050px) {
    #Buy .link-container {
        gap: 2%
    }
    #Buy .link-data {
        width: 35%;
        max-height: 20%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
    }
}

@media (max-width: 850px) {
    #Buy .link-container {
        flex-direction: column;
        gap: 5%
    }
    #Buy .link-data {
        width: 45%;
        max-height: 20%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
    }


}

@media (max-width: 550px) {
    #Buy .link-data {
        width: 80vw;
        height: 20%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
    }

    #Buy .button {
        margin-top: 0;
        width: 50vw;
    }
    #Buy .image {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    width: 70%;
    }
}

@media (max-width: 420px) {

    #Buy .button {
        margin-top: 0;
        width: 40vw;
    }
    
}
