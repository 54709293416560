#Overview {
    margin: 0;
    background-color: #0066FF;
    padding-top: 50px;
    min-height: 100vh;
    font-family: "Times New Roman", "TimesNewRoman ALT",  sans-serif;
    color: white;
}

#Overview .title {
    text-align: center;
    font-size: 2rem;
}

#Overview .review-title {
    text-align: center;
    font-size: 1.6rem;
}

#Overview .nobold {
    font-weight: normal;
}

#Overview .review-image {
    margin-left: 0;
    margin-right: auto;
    max-width: 450px;
    width: 65%;
}

#Overview .review-video {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
}

#Overview .review-video-container {
    margin-left: auto;
    margin-right: auto;
    height: 70vh;
    width: calc(70vh / 9 * 16);
    padding-bottom: 60px;    
}

#Overview .review-container { 
    margin-top: 120px;
    padding-top: 40px;
    background-color: #00AAFF;
}


#Overview .text-flexbox {
    margin-top: 20px; 
    padding-bottom: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 5%;

}

#Overview .text-content {
    margin-left: auto;
    margin-right: 0;
    text-align: center;
}

#Overview .title, #Overview .subtitle  {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

#Overview .subtitle {
    max-width: 600px;
    text-align: justify;
    font-size: 1.5rem;
    text-align: center;
}

#Overview .text {
    font-size: 1.2rem;
    text-align: center;
}

#Overview .review-text-container { 
    margin-top: 40px;
}

#Overview .chapter-container {
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 10%;

}

#Overview .chapter {
    flex: 0 1 300px;
    text-align: center;
    font-size: 1.2rem;

}

#Overview .chapterbox {
    padding: 5px 0 5px 0;
    max-width: 10rem;
    background-color: #AAAAAA;
    color: #333333;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
}

#Overview .review {
    margin-right: 0;
    padding-top: 20px;
    text-align: justify;
    font-size: 1.4rem;
}

#Overview .review-author {
    font-size: 1.35rem;
    margin-top: 0;
    padding-top: 0;
    text-align: center;
}

@media (max-width: 1050px) {

    #Overview .chapter {
        flex: 0 1 240px;
    }
    #Overview .chapter-container {
        gap: 6%;   
    }
    #Overview .review-image {
        width: 60%;
    }
}

@media (max-width: 940px) {

    #Overview .review-video-container {
        height: 60vh;
        width: calc(60vh / 9 * 16);
            
    }

}

@media (max-width: 880px) {
    #Overview .chapter {
        flex: 0 1 200px;
    }
    #Overview .chapter-container {
        gap: 5%;   
    }
    #Overview .review-image {        
        width: 40%;
    }
}

@media (max-width: 780px) {

    #Overview .review-video-container {
        height: 60vh;
        width: calc(60vh / 16 * 9);
            
    }

}

@media (max-width: 720px) {
    #Overview .chapter {
        flex: 0 0 140px;
    }
    #Overview .chapterbox {
        max-width: 8rem;
    }
    #Overview .text-flexbox {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #Overview .text-content, #Overview .review-image {
        margin-left: auto;
        margin-right: auto;
    }
    #Overview .review-image {
        margin-left: auto;
        width: 55%;
        min-width: 250px;
        max-width: 450px;
    }

}

@media (max-width: 550px) {

    #Overview .review-video-container {
        height: 70vh;
        width: calc(70vh / 16 * 9);   
    }
    #Overview .review-image {
        margin-left: auto;
        width: 65%;
    }
    #Overview .text {
        font-size: 1.2rem;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    #Overview .title {
        text-align: center;
        font-size: 1.5rem;
        width: 80%;
    }
    #Overview .chapter {
        flex: 0 0 140px;
    }
    #Overview .chapterbox {
        max-width: 7.5rem;
    }
    #Overview .chapter-container {
        gap: 4%;
    }
    
}

@media (max-width: 500px) {
    #Overview .chapter-container {
        gap: 2%;   
        flex-direction: column;
        width: 90vw;
        margin-left: 5vw;
        margin-right: 5vw;
    }
}

